.footer-carousel{
    border-top: 1px solid #C01722;
    border-bottom: 1px solid #C01722;
    margin: 0;
    padding: 0;
}

.tag_line{
    text-align: center;
    color: #fff;
    background-color: #c01722;
    margin: 0 auto;
    width: 409px;
    padding: 0px 0 0 0;
    height: 24px;
    border-radius: 0 0px 8px 8px;
}
.tag_line > p {
text-align: center !important;}

.fc-links .carousel-indicators{
    visibility: hidden;
}

.fc-links .carousel-control-prev, .fc-links .carousel-control-next{
    visibility: hidden;
}

.fc-links .carousel-inner .carousel-item a {
    color: #C01722;
}

.footer_nav ul li {
    list-style: none;
    padding: 0 2px;
    display: inline-block
}
.footer_nav ul  {
    padding:0
}
.footer_nav a {
    color:inherit;
    /* padding: 0 6px 0 0; */
    display: inline-block;
    padding: 0;
}


@media (max-width: 401px){
    .footer_nav {
        float: center;
    }
}
@media only screen and (max-width: 599px) {
    .tag_line {
        width: 180px;
        height:auto;
        text-align: none;
    }
    .tag_line p{
        width: 180px;
        text-align: center;
        
    }
    .tag_line > p {
        text-align: center !important;}
}
@media (min-width: 768px) {
  .footer_nav {
    float: right;
    margin-right: 20px;
  }
  .copyright {
    /* text-align: left !important; */
    padding-left: 20px !important;
  }
}