.navbar-expand-md .navbar-collapse {
    /* display: flex!important; */
    width: 1290px;
    flex-basis: auto;
}
.sideNav {
    color: #fff;
    position: fixed;
    /* left: 0px; */
    top: 0px;
    /* width: 1290px; */
    height: 100%;
    background-color: #9B1118;
    z-index: 1000000;
    display: flex;
    flex-direction: row;
    /* transform: translateX(-1200px); */
    transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.navbar-light .navbar-nav .nav-link {
    text-decoration: none;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: -1.75px;
    color: #C01722;
    transition: color 0.45s cubic-bezier(0.4, 0, 0.2, 1), transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.navbar {
    padding: 0 0 0;
}

.nav_item {
    position: relative;
    /* transform: translateX(-100%) translateX(-7.5rem); */
    transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
  }
  .navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: #C01722;
}

/* .collapse .nav_item {
  transform: translateX(0);
  opacity: 1;
}
  */
/* .collapse .nav_item:nth-child(1) {
  transition-delay: 0.19s;
}

.collapse .nav_item:nth-child(2) {
  transition-delay: 0.23s;
} */

/* .collapse .nav_item:nth-child(3) {
  transition-delay: 0.27s;
}

.collapse .nav_item:nth-child(4) {
  transition-delay: 0.31s;
}

.collapse .nav_item:nth-child(5) {
  transition-delay: 0.35s;
}

.collapse .nav_item:nth-child(6) {
  transition-delay: 0.39s;
} */
.dropdown:hover .nav-link, .nav-link:hover {
    color: #9B1118!important;
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1) skew(-15deg, 0deg);
    transition: color 0.45s cubic-bezier(0.4, 0, 0.2, 1), transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn-danger:hover{color: #fff!important;}
.nav_item.dropdown {
  display: flex;
  align-items: center;
}

.navbar-nav:hover .nav-link {
  color:#C01722;
}

.navbar-nav:hover .nav-link {
  color:#C01722;
}

.logoDiv {
    position: relative;
    display: block;
    top: 0px;
}

.dropdown-menu  {
    top: 78%;
    background: #9B1118;
    border: none;
    transition-delay: 1s;
    position: fixed
  }

.dropdown-menu  .dropdown-item {
    color: whitesmoke;
    display: block;
    /* padding: 0.625rem 0; */
    position: relative;
    font-size: 18px;
    letter-spacing: -0.63px;
    transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.dropdown-item:focus, .dropdown-item:hover {
    text-decoration: none;
    background-color: transparent;
    transform: translateX(0.3125rem);
    color: whitesmoke;
    font-weight: bold;
}

.dropdown-toggle::after {
    border: none!important;
    content: "";
    display: inline-block;
    height: 1px;
    background-color: #C01722;
    color: white;
    flex-grow: 1;
    transform: translateX(-0.625rem) scaleX(0);
    transform-origin: center left;
    transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
    min-width: 9rem;
    display: none;
    margin: 0 2.1875rem;
}
    
.dropdown-toggle:hover {
    z-index: 2;
}

.dropdown-toggle:hover::after {
    transform: rotate(90deg) translateY(0) scaleY(1);
    transition-delay: 0.1s;
}

/* .dropdown-item:hover .dropdown-toggle::after {
transform: translateX(0) scaleX(1)!important;
} */



.dropdown-toggle:hover .dropdown-menu .show {
    display: block;
    transform: translateX(0);
    transition-delay: 0s;
}
.dropdown:hover .dropdown-item {
    transform: translateX(0);
    opacity: 1;
}

.dropdown-item:nth-child(1) {
    transition-delay: 0.2s;
}

.dropdown-item:nth-child(2) {
    transition-delay: 0.1s;
}

.dropdown-item:nth-child(3) {
    transition-delay: 0.0666666667s;
}

.dropdown-item:nth-child(4) {
    transition-delay: 0.05s;
}

.dropdown-item:nth-child(5) {
    transition-delay: 0.04s;
}

.dropdown-item:nth-child(6) {
    transition-delay: 0.0333333333s;
}

/* .dropdown:hover .dropdown-item:nth-child(1) {
transition-delay: 0.34s;
}


.dropdown:hover .dropdown-item:nth-child(2) {
transition-delay: 0.38s;
}

.dropdown:hover .dropdown-item:nth-child(3) {
transition-delay: 0.42s;
}

.dropdown:hover .dropdown-item:nth-child(4) {
transition-delay: 0.46s;
}

.dropdown:hover .dropdown-item:nth-child(5) {
transition-delay: 0.5s;
}

.dropdown:hover .dropdown-item:nth-child(6) {
transition-delay: 0.54s;
} */

.social_links {
    float:right;
    width: auto;
    /*top: 90px*/;
    /* right: 20px; */
    position:relative;
}
.social_links ul li {
    list-style:none;
    padding: 3px 0;
}




@media (hover: hover) and (pointer: fine){
    .dropdown:hover .dropdown-menu {
        display: block;
        transition: visibility 0s 0.6s;
    }
}

@media (min-width: 700px){
    .dropdown-item:hover {
        transform: translateX(0.525rem)!important;
    }
}

@media (min-width: 992px) {
    .dropdown .dropdown-item {
        opacity: 0;
        transform: translateX(-30%);
    }
}
@media (max-width: 598px){
    .yearsOfCompletion {
        display: none;
    }
}

@media (min-width: 768px){
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.3rem;
        padding-left: .5rem;
    }
}

@media (max-width: 768px){
    .navbar-light .navbar-nav .dnav-link {
        font-size: 24px;
    }
    .navbar-brand.abs{
      position: absolute;
      width: 100%;
      left: 0;
      text-align: center;
    }
    .yearsOfCompletion, .social_links {
        display: none;
    }
    .dropdown:hover .nav-link, .nav-link:hover {
        transform: scale(0.85) skew(-5deg, 0deg);
    }
}
@media screen and (max-width: 459px){
    .social_links {
        text-align: center;
        width: 100%;
        right: 0;
        padding: 0;
        margin: 8px 0;
    }
    .social_links  ul {padding:0}
}

@media (max-width:940px) {
  .social_links ul li {display: inline-block !important; margin-left: 7px;}
  .social_links {
    top: 100px !important;
    position: absolute !important;
    right: 9px;
    }
    .no-profile{
        padding: 50px 0 0 0;
        font-size: 25px;
    }
 }

@media (min-width: 941px) {
  .social_links  ul{padding:0;}
}